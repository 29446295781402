<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="$bvModal.show('competitionModal')"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationBasicInfo"
            title="Basic Information"
          >
            <validation-observer
              ref="basicInfo"
              tag="form"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    :label-class="`h5`"
                    label="Select Organisation"
                  >
                    <v-select
                      id="selectStartup"
                      v-model="competition.organisation_id"
                      :get-option-label="option => option.users_organizationtable.title"
                      :options="userOrgs"
                      :reduce="org => org.organization_id"
                      placeholder="Choose one from the list"
                    />
                    <hr>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-overlay
                :show="!competition.organisation_id"
                rounded="sm"
              >
                <template #overlay>
                  <b-card
                    bg-variant="primary"
                    text-variant="light"
                  >
                    Please Select Organisation
                  </b-card>
                </template>
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Basic Information
                    </h5>
                  </b-col>
                  <b-col
                    md="12"
                    class="d-flex mb-2"
                  >
                    <h4
                      class="mb-0 mr-1"
                      style="padding-top:2px"
                    >
                      <strong>Open To: </strong>
                    </h4>
                    <b-form-checkbox
                      :checked="competition.public"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      @change="updateStatus(!competition.public)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <div class="d-flex align-items-center">
                      <b-badge
                        :variant="competition.public?'light-primary':'light-danger'"
                        style="text-transform: capitalize;font-size:16px;"
                      >
                        <strong>{{ competition.public ? "Everyone" : "Inhouse Startups" }}</strong>
                      </b-badge>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="competition Type"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Competition Type"
                        label-for="competitionType"
                      >
                        <v-select
                          id="competitionType"
                          v-model="competition.type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Hackathons', 'Ideathons','Startathons','Business Plan Competitions', 'Demo Days','Investment Challenges','Pitch Competitions','Growth-Stage Contests','Social Venture Challenges']"
                          label="Competition Type"
                          placeholder="Competition Type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Intended Participation"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Intended Participants"
                        label-for="intendedParticipation"
                      >
                        <v-select
                          id="intendedParticipation"
                          v-model="competition.participation"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Startups', 'Students', 'Mentors', 'Partners']"
                          chips
                          multiple
                          label="Intended Participants"
                          placeholder="Intended Participants"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Competition Title"
                      label-for="competitionTitle"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Competition Title"
                        rules="required"
                      >
                        <b-form-input
                          id="competitionTitle"
                          v-model="competition.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Competition Title"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Intended Participation"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Delivery Mode"
                        label-for="deliveryMode"
                      >
                        <v-select
                          id="deliveryMode"
                          v-model="competition.deliveryMode"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Offline', 'Online', 'Hybrid']"
                          label="Delivery Mode"
                          placeholder="Delivery Mode"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Start date & time"
                      rules="required"
                    >
                      <b-form-group
                        class="mb-2"
                        label="Select Start date & time"
                        label-for="SelectStartDateAndTime"
                      >
                        <flat-pickr
                          id="SelectStartDateAndTime"
                          v-model="competition.beginTime"
                          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                          class="form-control"
                          placeholder="Select start date & time"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="End date & time"
                      rules="required"
                    >
                      <b-form-group
                        class="mb-2"
                        label="Select End date & time"
                        label-for="SelectEndDateAndTime"
                      >
                        <flat-pickr
                          id="SelectEndDateAndTime"
                          v-model="competition.endTime"
                          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                          class="form-control"
                          placeholder="Select end date & time"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Competition Description"
                      label-for="competitionDescription"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Competition Description"
                        rules="required"
                      >
                        <b-form-input
                          id="competitionDescription"
                          v-model="competition.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Competition Description"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Capacity"
                      label-for="competitionCapacity"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Capacity"
                        rules="required"
                      >
                        <b-form-input
                          id="competitionCapacity"
                          v-model="competition.capacity"

                          :state="errors.length > 0 ? false:null"
                          placeholder="Number of participants"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <!-- Styled -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="File"
                      rules="size:10000"
                    >
                      <b-form-group
                        label="Upload Competition Poster(Image)"
                        label-for="competitionPoster"
                      >
                        <b-form-file
                          v-model="competition.file"
                          accept="image/jpeg, image/png, image/gif"
                          style="margin-bottom: 0.5rem;"
                          :state="errors.length > 0 ? false:null"
                          drop-placeholder="Drop file here..."
                          placeholder="Choose a file or drop it here..."
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <h5 class="mb-0.5">
                      Competition Prize
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label=""
                        label-for="competitionPrize"
                      >
                        <v-select
                          id="competitionPrice"
                          v-model="competition.prize"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Award', 'Cash Prize', 'Certificates', 'Grants & Fellowships', 'Investment Opportunities', 'Recognition', 'Partner Services Offers', 'Other Perks, Offers and Benefits',]"
                          multiple
                          label=""
                          placeholder="Select Competition Prize"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <b-col md="12">
                      <div
                        v-for="(item, i) in competition.prize"
                        :key="i"
                      >
                        <b-row v-if="item==='Certificates'">
                          <b-col
                            md="6"
                            class="d-flex mb-1"
                          >
                            <h6
                              class="mb-0 mr-1"
                              style="padding-top:2px"
                            >
                              Will everyone receive the certificate?
                            </h6>
                            <b-form-checkbox
                              v-b-tooltip="competition.certificate ? 'Yes' : 'No'"
                              :checked="competition.certificate"
                              class="custom-control-primary"
                              name="check-button"
                              switch
                              @change="updateCertificate(!competition.certificate)"
                            >
                              <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                              </span>
                              <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                              </span>
                            </b-form-checkbox>
                            <div class="">
                              <b-badge
                                :variant="competition.certificate?'light-primary':'light-danger'"
                                style="text-transform: capitalize;font-size:16px;"
                              >
                                <strong>{{ competition.certificate ? "Yes" : "No" }}</strong>
                              </b-badge>
                            </div>
                          </b-col>
                          <b-col
                            v-if="!competition.certificate"
                            md="6"
                          >
                            <b-form-group
                              label="Number/Percentage of Recipients"
                              label-for="certificateCapacity"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Number/Percentage of Recipients"
                              >
                                <b-form-input
                                  id="certificateCapacity"
                                  v-model="competition.certificateCapacity"

                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Number/Percentage of Recipients,"
                                  type="number"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-else-if="item==='Cash Prize'">
                          <b-col md="6">
                            <b-form-group
                              label="Total Prize Pool"
                              label-for="competitionPrize"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Total Prize Pool"
                              >
                                <b-form-input
                                  id="competitionPrize"
                                  v-model="competition.cashPrize"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Total Prize Pool"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Cash Prize of 1st Place (or equivalent)"
                              label-for="competitionPriceNumber"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Cash Prize of 1st Place (or equivalent)"
                              >
                                <b-form-input
                                  id="competitionPriceNumber"
                                  v-model="competition.cashPrizeNumber"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Cash Prize of 1st Place (or equivalent)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-else-if="item==='Investment Opportunities'">
                          <b-col md="6">
                            <b-form-group
                              label="Total Fund Size,"
                              label-for="prizeInvestment"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Total Fund Size,"
                              >
                                <b-form-input
                                  id="prizeInvestment"
                                  v-model="competition.investment"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Total Fund Size,"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Number/Percentage of Recipients"
                              label-for="prizeInvestmentNumber"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Number/Percentage of Recipients"
                              >
                                <b-form-input
                                  id="prizeInvestmentNumber"
                                  v-model="competition.investmentNumber"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Number/Percentage of Recipients"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-else-if="item==='Other Perks, Offers and Benefits'">
                          <b-col md="6">
                            <b-form-group
                              label="Specify Perks, Offers and Benefits Details"
                              label-for="competitionPerks"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Specify Perks, Offers and Benefits Details"
                              >
                                <b-form-input
                                  id="competitionPerks"
                                  v-model="competition.prizePerks"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Perks, Offers and Benefits Details"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-else-if="item==='Award'">
                          <b-col md="6">
                            <b-form-group
                              :label="`Specify Award Details`"
                              label-for="awardPrize"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="`Award`"
                              >
                                <b-form-input
                                  id="awardPrize"
                                  v-model="competition.awardtitle"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="`Specify Award Details`"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Number/Percentage of Recipients"
                              label-for="competitionPriceNumber"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Number/Percentage of Recipients"
                              >
                                <b-form-input
                                  id="competitionPriceNumber"
                                  v-model="competition.awarddescription"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Number/Percentage of Recipients"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-else-if="item==='Grants & Fellowships'">
                          <b-col md="6">
                            <b-form-group
                              :label="`Specify Grant & Fellowship Details`"
                              label-for="grantPrize"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="`Grant & Fellowship`"
                              >
                                <b-form-input
                                  id="grantPrize"
                                  v-model="competition.granttitle"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="`Specify Grant & Fellowship Details`"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Number/Percentage of Recipients"
                              label-for="competitionPriceNumber"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Number/Percentage of Recipients"
                              >
                                <b-form-input
                                  id="competitionPriceNumber"
                                  v-model="competition.grantdescription"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Number/Percentage of Recipients"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-else-if="item==='Recognition'">
                          <b-col md="6">
                            <b-form-group
                              :label="`Specify Recognition Details`"
                              label-for="competitionPrize"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="`Recognition`"
                              >
                                <b-form-input
                                  id="competitionPrize"
                                  v-model="competition.recognitiontitle"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="`Specify Recognition Details`"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Number/Percentage of Recipients"
                              label-for="competitionPriceNumber"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Number/Percentage of Recipients"
                              >
                                <b-form-input
                                  id="competitionPriceNumber"
                                  v-model="competition.recognitiondescription"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Number/Percentage of Recipients"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-else-if="item==='Partner Services Offers'">
                          <b-col md="6">
                            <b-form-group
                              :label="`Specify Partner Services Offers Details`"
                              label-for="competitionPrize"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="`Partner Services Offers`"
                              >
                                <b-form-input
                                  id="competitionPrize"
                                  v-model="competition.partnertitle"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="`Specify Partner Services Offers Details`"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Number/Percentage of Recipients"
                              label-for="competitionPriceNumber"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Number/Percentage of Recipients"
                              >
                                <b-form-input
                                  id="competitionPriceNumber"
                                  v-model="competition.partnerdescription"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="Enter Number/Percentage of Recipients"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-col>
                  <!-- criteria start -->
                  <!-- criteria end -->
                  <b-col
                    md="12"
                    class="d-flex mb-2"
                  >
                    <h5
                      class="mb-0 mr-1"
                      style="padding-top:2px"
                    >
                      Is this a team competition?
                    </h5>
                    <b-form-checkbox
                      v-b-tooltip="competition.compType ? 'Yes' : 'No'"
                      :checked="competition.compType"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      @change="updateCompType(!competition.compType)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <div class="d-flex align-items-center">
                      <b-badge
                        :variant="competition.compType?'light-primary':'light-danger'"
                        style="text-transform: capitalize;font-size:16px;"
                      >
                        <strong>{{ competition.compType ? "Yes" : "No" }}</strong>
                      </b-badge>
                    </div>
                  </b-col>
                  <!-- limit component -->
                  <b-col
                    v-if="competition.compType"
                    md="4"
                  >
                    <b-form-group
                      label="Minimum team size"
                      label-for="fundRaisingSize_l"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Minimum  team size"
                        rules="required"
                      >
                        <b-form-input
                          id="fundRaisingSize_l"
                          v-model="competition.teamSize[0]"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter minimum team size"
                          type="number"
                          @change="handleminparticipants"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="competition.compType"
                    md="4"
                  >
                    <b-form-group
                      label="Maximum team size"
                      label-for="fundRaisingSize_u"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Maximum team size"
                        :rules="competition.noTeamLimit ? '': 'required'"
                      >
                        <b-form-input
                          id="fundRaisingSize_u"
                          v-model="competition.teamSize[1]"
                          :disabled="competition.noTeamLimit"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter maximum team size"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="competition.compType"
                    md="4"
                  >
                    <b-form-group
                      label=""
                    >
                      <div class="d-flex">
                        <b-form-checkbox-group
                          v-model="competition.noTeamLimit"
                          style=" font-size: 3rem; font-weight: 500;"
                          :options="[{ text: 'No limit on team size', value: 'noLimit'},]"
                          class="d-inline"
                          disabled-field="disabled"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- limit component -->
                </b-row>
              </b-overlay>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
    <!-- modal starts -->
    <b-modal
      id="competitionModal"
      :ok-title="`${(competition.lifeCycleStage && competition.industry && competition.customerFocus && competition.offeringsType && competition.impactFocus && competition.lifeCycleStage.length === 0 && competition.industry.length === 0 && competition.customerFocus.length === 0 && competition.offeringsType.length === 0 && competition.impactFocus.length === 0)?'Skip':'Save'}`"
      no-close-on-backdrop
      size="lg"
      title="Additional Details"
      @ok="handleNewSave"
    >
      <h4>You can select multiple options.</h4>
      <b-row>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="Ideal Startup Lifecycle Stage"
          >
            <b-form-group
              :state="errors.length > 0 ? false:null"
              label="Ideal Startup Lifecycle Stage"
              label-for="lifeCycleStage"
            >
              <v-select
                id="lifeCycleStage"
                v-model="competition.lifeCycleStage"
                :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                multiple
                placeholder="Select one or multiple options"
                style="z-index: 1000;"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="Participant Industry/Sector"
          >
            <b-form-group
              :state="errors.length > 0 ? false:null"
              label="Participant Industry/Sector"
              label-for="industry"
            >

              <v-select
                id="industry"
                v-model="competition.industry"
                :options="industries"
                multiple
                placeholder="Select one or multiple options"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <h6><strong>Focus</strong></h6>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Customer Focus"
            label-for="customerFocus"
          >
            <v-select
              id="customerFocus"
              v-model="competition.customerFocus"
              :options="['B2C', 'D2C', 'B2B', 'B2G', 'B2B2C', 'B2B2B', 'B2C2B', 'B2C2C', 'B2B2G', 'B2G2B', 'B2G2C', 'B2C2G']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Offerings Type"
            label-for="offeringsType"
          >
            <v-select
              id="offeringsType"
              v-model="competition.offeringsType"
              :options="['Product', 'Service', 'Hybrid']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Impact Focus"
            label-for="impactFocus"
          >
            <v-select
              id="impactFocus"
              v-model="competition.impactFocus"
              :options="['Profit', 'Social', 'Environmental']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
    </b-modal>
    <b-modal
      id="submitModal"
      ok-title="Yes, Submit"
      ok-variant="success"
      button-size="sm"
      no-close-on-backdrop
      size="sm"
      title="Confirm Submit"
      @cancel="$bvModal.show('competitionModal')"
      @ok="addCompetition()"
    >
      <h6>Adding additional details helps you get the right applicants and participants. Are you sure you do not want to add details?</h6>
    </b-modal>
    <!-- modal ends -->
  </b-row>
</template>

<script>
import {
  // BButton,
  BCard,
  // BCardText,
  BCol,
  BBadge,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
  // BTab,
  // BTabs,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { getUserData } from '@/utils/auth'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    flatPickr,
    BFormFile,
    BBadge,
    // AppTimeline,
    // AppTimelineItem,
    // BTabs,
    // BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    // BCardText,

    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      competition: {
        mutationLoading: false,
        organisation_id: null,
        compType: true,
        criteriaList: [],
        competitionId: null,
        title: null,
        type: null,
        prize: [],
        prizeDetail: {},
        teamSize: [1, null],
        noTeamLimit: false,
        investmentNumber: null,
        certificateCapacity: 0,
        investment: null,
        cashPrize: null,
        cashPrizeNumber: null,
        certificate: true,
        prizePerks: null,
        fundraisingLimit: null,
        awardtitle: null,
        awarddescription: null,
        granttitle: null,
        grantdescription: null,
        recognitiontitle: null,
        recognitiondescription: null,
        partnertitle: null,
        partnerdescription: null,
        participation: null,
        description: null,
        deliveryMode: null,
        capacity: null,
        beginTime: null,
        endTime: null,
        lifeCycleStage: [],
        industry: [],
        customerFocus: [],
        offeringsType: [],
        impactFocus: [],
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: ['', ''],
        inhouseFunds: null,
        file: null,
        public: true,
        criteria: [
          {
            criteria: null,
            description: null,
          },
        ],
        deliverables: [
          {
            deliverable: null,
            description: null,
          },
        ],
        phases: [
          {
            title: null,
            description: null,
            objectives: [
              {
                title: null,
                description: null,
              },
            ],
            subphases: [{
              title: null,
              description: null,
              days: [
                {
                  activities: [
                    {
                      title: null,
                      operations_type: null,
                      duration: null,
                      description: null,
                      objectives: [
                        {
                          title: null,
                          description: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            }],
          },
        ],
      },
      is_internal: true,
      mutationLoading: false,
      phasesTab: null,
      required,
      email,
      industries: ['Agnostic',
        'Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      if (!arr.length) return []
      const newarr = arr
        .filter(obj => obj.role === 'superadmin')
        .filter(obj => obj) // Remove nulls
      this.handleselection(newarr)
      return newarr
    },
  },
  methods: {
    /* eslint-disable no-param-reassign */
    handleminparticipants() {
      if (this.competition.teamSize[0] < 1) {
        this.competition.teamSize[0] = 1
      }
    },
    handleselection(getarr) {
      if (getarr.length > 0) {
        this.competition.organisation_id = getarr[0].organization_id
      }
    },
    updateStatus(newstatus) {
      this.competition.public = newstatus
    },
    updateCertificate(newstatus) {
      this.competition.certificate = newstatus
    },
    updateCompType(newstatus) {
      this.competition.compType = newstatus
    },
    handleNewSave() {
      const {
        lifeCycleStage, industry, customerFocus, offeringsType, impactFocus,
      } = this.competition
      if (
        (!lifeCycleStage || lifeCycleStage.length === 0)
          && (!industry || industry.length === 0)
          && (!customerFocus || customerFocus.length === 0)
          && (!offeringsType || offeringsType.length === 0)
          && (!impactFocus || impactFocus.length === 0)
      ) {
        this.$bvModal.show('submitModal')
      } else {
        this.addCompetition()
      }
    },
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addCompetition()
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please Wait! Creating Program...',
          icon: 'LoaderIcon',
          variant: 'info',
        },
      })
    },

    async addCompetition() {
      this.mutationLoading = true
      const temp = []
      this.competition.prize.forEach(pdata => {
        switch (pdata) {
          case 'Certificates': temp.push({
            deliverable: 'Certificates',
            available_for_all: this.competition.certificate,
            number: this.competition.certificateCapacity.toString(),
          })
            break
          case 'Cash Prize': temp.push({
            deliverable: 'Cash Prize',
            details: this.competition.cashPrize,
            number: this.competition.cashPrizeNumber.toString(),
          })
            break
          case 'Investment Opportunities': temp.push({
            deliverable: 'Investment Opportunities',
            details: this.competition.investment,
            number: this.competition.investmentNumber.toString(),
          })
            break
          case 'Other Perks, Offers and Benefits': temp.push({
            deliverable: 'Others',
            details: this.competition.prizePerks,
          })
            break
          case 'Award': temp.push({
            deliverable: 'Award',
            details: this.competition.awardtitle,
            number: this.competition.awarddescription,
          })
            break
          case 'Grants & Fellowships': temp.push({
            deliverable: 'Grants & Fellowships',
            details: this.competition.granttitle,
            number: this.competition.grantdescription,
          })
            break
          case 'Recognition': temp.push({
            deliverable: 'Recognition',
            details: this.competition.recognitiontitle,
            number: this.competition.recognitiondescription,
          })
            break
          case 'Partner Services Offers': temp.push({
            deliverable: 'Partner Services Offers',
            details: this.competition.partnertitle,
            number: this.competition.partnerdescription,
          })
            break
          default:
            break
        }
      })
      let fileLink = null
      if (this.competition.file) {
        try {
          fileLink = await this.uploadFile(this.competition.file)
        } catch (error) {
          this.showAlert('Error uploading file!', 'Error')
        } finally {
          this.mutationLoading = false
        }
      }
      try {
        await this.$apollo.mutate({
        // Query
          mutation: gql`
        mutation (
          $deliverableInput: [programs_deliverablelisttable_insert_input!]!
          $status: String!
          $isCompetition: Boolean!
          $type: String!
          $targetParticipants: String!
          $isPublic: Boolean!
          $title: String!
          $deliveryMode: String!
          $beginDate: timestamptz!
          $endDate: timestamptz!
          $description: String!
          $image: String
          $isTeam: Boolean!
          $minParticipants: Int!
          $maxParticipants: Int
          $organizationId: Int!
          $idealLifecycleStage: String!
          $capacity: Int!
          $industry: String!
          $customerType: String!
          $offeringsType: String!
          $focusType: String!
        ) {
          insert_programs_basicinfo_one(
            object: {
              status: $status,
              is_competition: $isCompetition,
              type: $type,
              target_participants: $targetParticipants,
              is_public: $isPublic,
              title: $title,
              delivery_mode: $deliveryMode,
              begin_date: $beginDate,
              end_date: $endDate,
              description: $description,
              image: $image,
              is_team: $isTeam,
              min_participants: $minParticipants,
              max_participants: $maxParticipants,
              organization_id_id: $organizationId,
              ideal_lifecycle_stage: $idealLifecycleStage,
              capacity: $capacity,
              industry: $industry,
              customer_type: $customerType,
              offerings_type: $offeringsType,
              focus_type: $focusType,
              programs_deliverablelisttables: { data: $deliverableInput }
            }
          ) {
            id
          }
        }
      `,
          variables: {
            deliverableInput: temp,
            status: 'draft',
            isCompetition: true,
            type: this.competition.type,
            targetParticipants: this.competition.participation.join(', '),
            isPublic: this.competition.public,
            title: this.competition.title,
            deliveryMode: this.competition.deliveryMode,
            beginDate: this.competition.beginTime,
            endDate: this.competition.endTime,
            description: this.competition.description,
            image: fileLink,
            isTeam: this.competition.compType,
            minParticipants: this.competition.teamSize[0],
            maxParticipants: this.competition.noTeamLimit ? null : this.competition.teamSize[1],
            organizationId: parseInt(this.competition.organisation_id, 10),
            idealLifecycleStage: this.competition.lifeCycleStage.join(', '),
            capacity: this.competition.capacity,
            industry: this.competition.industry.join(', '),
            customerType: this.competition.customerFocus.join(', '),
            offeringsType: this.competition.offeringsType.join(', '),
            focusType: this.competition.impactFocus.join(', '),
          },
          update: (store, { data: { insert_programs_basicinfo_one } }) => {
            if (insert_programs_basicinfo_one.id) this.showAlert('Competition created successfully', 'success')
            else this.showAlert('Failed to create competition', 'danger')
            if (insert_programs_basicinfo_one.id) {
              this.$router.push({ name: 'manage-competitions' })
            } else {
            // Error Occurred, stop loading
              this.mutationLoading = false
            }
          },
        })
      } catch (error) {
        this.showAlert('Failed to create competition', 'danger')
        this.mutationLoading = false
      }
    },
    validationBasicInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfo.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
